export default {
  static: {
    xs: "320px",
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    "2xl": "1536px",
  },
  dynamic: {
    ssl: 100000,
    md: 768,
    xl: 1280,
    lg: 1024,
    sm: 640,
    "2xl": 1536,
    xs: 320,
  },
};
